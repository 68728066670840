import React, {useState} from 'react';
import VSensor from 'react-visibility-sensor';
import {Spring} from 'react-spring/renderprops';

const VisibilitySensor = (props) => {
    const [active, setActive] = useState(true);
    const { once, children, ...theRest } = props;

    return (
        <VSensor
            active={active}
            onChange={isVisible =>
                once &&
                isVisible &&
                setActive(false)
            }
            partialVisibility
            {...theRest}
        >
            {({ isVisible }) => (
                <Spring delay={100} to={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible ? "translateY(0)" : "translateY(50px)"
                }}>
                    {props => <div style={{...props}} className={isVisible ? 'component-visible' : 'component-hidden'}>{children}</div>}
                </Spring>
            )}
        </VSensor>
    );
};

VisibilitySensor.defaultProps = {
    once: true
};

export default VisibilitySensor;